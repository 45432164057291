import gsap from 'gsap';
import Ease from '../_utils/Ease';
import { getCssVar } from '../_utils/Utils';

function Opening() {
	//cookie から opening の値を取得
	const cookieValue = () => {
		if (
			document.cookie &&
			document.cookie.split('; ').find((row) => row.startsWith('opening'))
		) {
			return document.cookie
				.split('; ')
				.find((row) => row.startsWith('opening'))
				.split('=')[1];
		} else {
			return;
		}
	};
	const param = new URLSearchParams(window.location.search);

	// 背面固定
	const backLock = (isLoack) => {
		const styles = {
			position: 'fixed',
			top: '0',
			left: '0',
			width: '100%',
			height: '100%',
			overflow: 'hidden scroll',
		};

		Object.keys(styles).forEach((key) => {
			document.documentElement.style[key] = isLoack ? styles[key] : '';
		});
	};

	const $nav = document.querySelector('.js-op-nav');
	const $open = document.querySelector('.js-op-open');
	const $ethical = document.querySelector('.js-op-ethical');
	const $$pen = document.querySelectorAll('.js-op-pen');
	const $pencilcase = document.querySelector('.js-op-pencilcase');
	const $takepenBody = document.querySelector('.js-op-takepen-body');
	const $takepenCap = document.querySelector('.js-op-takepen-cap');
	const $handsoap = document.querySelector('.js-op-handsoap');
	const $kamipen = document.querySelector('.js-op-kamipen');
	const $firstViewArray = [
		$nav,
		$open,
		$ethical,
		...$$pen,
		$pencilcase,
		$takepenCap,
		$handsoap,
		$kamipen,
	];

	if (cookieValue() === 'yes' && !param.has('opening')) {
		gsap.set($takepenBody, {
			opacity: 0,
		});

		gsap.to(document.body, {
			opacity: 1,
			duration: 0.4,
			ease: Ease.DoubleSineOut,
		});

		// $firstViewArray.forEach(($el) => {
		// 	$el.classList.add('is-animated');
		// });
	} else {
		backLock(true);

		gsap.set($firstViewArray, {
			opacity: 0,
		});

		gsap.set(document.body, {
			opacity: 1,
		});

		// cookie
		document.cookie = 'opening=yes; max-age=259200; path=/';

		const op = gsap.timeline({
			delay: 0.4,
			onComplete: () => {
				backLock(false);

				// $firstViewArray.forEach(($el) => {
				// 	$el.classList.add('is-animated');
				// });
			},
		});

		const handsoapX = Number(getCssVar($handsoap, '--_x'));
		const handsoapY = Number(getCssVar($handsoap, '--_y'));
		op.fromTo(
			$handsoap,
			{
				opacity: 0,
				xPercent: handsoapX,
				yPercent: handsoapY,
			},
			{
				keyframes: {
					0: {
						opacity: 0,
						xPercent: handsoapX,
						yPercent: handsoapY,
					},
					10: {
						opacity: 1,
					},
					33: {
						xPercent: handsoapX * -0.3,
						yPercent: handsoapY * 0.6,
						rotate: 16,
					},
					66: {
						xPercent: handsoapX * 0.3,
						yPercent: handsoapY * 0.3,
						rotate: -8,
					},
					100: {
						xPercent: 0,
						yPercent: 0,
						rotate: 0,
					},
				},
				duration: 2,
				ease: 'steps(3)',
			}
		);

		const kamipenX = Number(getCssVar($kamipen, '--_x'));
		const kamipenY = Number(getCssVar($kamipen, '--_y'));
		op.fromTo(
			$kamipen,
			{
				opacity: 0,
				xPercent: kamipenX,
				yPercent: kamipenY,
			},
			{
				keyframes: {
					0: {
						opacity: 0,
						xPercent: kamipenX,
						yPercent: kamipenY,
					},
					10: {
						opacity: 1,
					},
					33: {
						xPercent: kamipenX * -0.3,
						yPercent: kamipenY * 0.6,
						rotate: -16,
					},
					66: {
						xPercent: kamipenX * 0.3,
						yPercent: kamipenY * 0.3,
						rotate: 8,
					},
					100: {
						xPercent: 0,
						yPercent: 0,
						rotate: 0,
					},
				},
				duration: 2,
				ease: 'steps(3)',
			},
			'<'
		);

		const pencilcaseX = Number(getCssVar($pencilcase, '--_x'));
		const pencilcaseY = Number(getCssVar($pencilcase, '--_y'));
		op.fromTo(
			$pencilcase,
			{
				opacity: 0,
				xPercent: pencilcaseX,
				yPercent: pencilcaseY,
			},
			{
				keyframes: {
					0: {
						opacity: 0,
						xPercent: pencilcaseX,
						yPercent: pencilcaseY,
					},
					10: {
						opacity: 1,
					},
					33: {
						xPercent: pencilcaseX * 0.6,
						yPercent: pencilcaseY * 0.6,
						rotate: -32,
					},
					66: {
						xPercent: pencilcaseX * 0.3,
						yPercent: pencilcaseY * 0.3,
						rotate: 24,
					},
					100: {
						xPercent: 0,
						yPercent: 0,
						rotate: 0,
					},
				},
				duration: 2,
				ease: 'steps(3)',
			},
			'<'
		);

		$$pen.forEach(($pen, index) => {
			const x = Number(getCssVar($pen, '--_x'));
			const y = Number(getCssVar($pen, '--_y'));

			const timing = index == 0 ? '+=0.1' : '<';

			op.fromTo(
				$pen,
				{
					opacity: 0,
					xPercent: x,
					yPercent: y,
				},
				{
					keyframes: {
						0: {
							opacity: 0,
							xPercent: x,
							yPercent: y,
							scaleY: 0.5,
						},
						30: {
							opacity: 1,
						},
						70: {
							scaleY: 1,
						},
						80: {
							xPercent: x * -0.1,
							yPercent: y * -0.1,
						},
						100: {
							xPercent: 0,
							yPercent: 0,
						},
					},
					duration: 1,
					ease: Ease.DoubleSineOut,
				},
				timing
			);
		});

		const takepenBodyX = Number(getCssVar($takepenBody, '--_x'));
		const takepenBodyY = Number(getCssVar($takepenBody, '--_y'));
		op.fromTo(
			$takepenBody,
			{
				opacity: 0,
				xPercent: takepenBodyX,
				yPercent: takepenBodyY,
			},
			{
				keyframes: {
					0: {
						opacity: 0,
						xPercent: takepenBodyX,
						yPercent: takepenBodyY,
					},
					30: {
						opacity: 1,
					},
					80: {
						xPercent: takepenBodyX * -0.1,
						yPercent: takepenBodyY * -0.1,
					},
					100: {
						yPercent: 0,
						xPercent: 0,
					},
				},
				duration: 1,
				ease: Ease.DoubleSineOut,
			},
			'<'
		);

		op.to($takepenBody, {
			opacity: 0,
			duration: 0.4,
			ease: Ease.DoubleSineOut,
		});

		op.fromTo(
			$takepenCap,
			{
				opacity: 0,
			},
			{
				opacity: 1,
				duration: 0.4,
				ease: Ease.DoubleSineOut,
			},
			'<'
		);

		op.fromTo(
			[$nav, $open, $ethical],
			{
				opacity: 0,
			},
			{
				opacity: 1,
				duration: 0.6,
				ease: Ease.DoubleSineOut,
			},
			'<'
		);
	}
}

export default Opening;
