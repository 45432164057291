// css
import gsap from 'gsap';
import '../../scss/pages/home.scss';
import { Modal } from '../_libs/Modal';

// utils
import foundation from '../_utils/foundation';
import Ease from '../_utils/Ease';
import Opening from '../_libs/Opening';
import { getCssVar } from '../_utils/Utils';
foundation();

document.addEventListener('DOMContentLoaded', () => {
	const fvModal = new Modal({
		modal: '.js-fv-modal',
		open: '.js-fv-modal-open',
		close: '.js-fv-modal-close',
		html: 'is-fv-modal-open',
		overlay: '.js-fv-modal-overlay',
		globalContainer: '.js-container',
	});
	fvModal.init();

	const $fvPopupHover = document.querySelector('.js-fv-popup-hover');
	if ($fvPopupHover) {
		const $fvPopup = document.querySelector('.js-fv-popup');
		gsap.set($fvPopup, {
			scale: 0,
		});

		$fvPopupHover.addEventListener('mouseenter', () => {
			gsap.fromTo(
				$fvPopup,
				{
					scale: 0,
					opacity: 1,
				},
				{
					scale: 1,
					opacity: 1,
					duration: 0.6,
					ease: 'elastic.out(1, 0.6)',
				}
			);
		});
		$fvPopupHover.addEventListener('mouseleave', () => {
			gsap.to($fvPopup, {
				opacity: 0,
				duration: 0.4,
				overwrite: 'auto',
				ease: Ease.DoubleSineOut,
			});
		});
	}

	// Opening
	Opening();

	const $$sliderItem = document.querySelectorAll('.js-slider-item');
	if ($$sliderItem) {
		let randomIndex = Math.floor(Math.random() * $$sliderItem.length);
		setInterval(() => {
			// 前回と同じインデックスの場合は再度ランダム
			let newIndex;
			do {
				newIndex = Math.floor(Math.random() * $$sliderItem.length);
			} while (randomIndex === newIndex);

			randomIndex = newIndex;

			$$sliderItem[randomIndex].classList.toggle('is-current');
		}, 2400);
	}

	const $$voice = document.querySelectorAll('.js-home-voice');
	if ($$voice.length > 0) {
		const mm = gsap.matchMedia();

		mm.add('screen and (min-width: 768px)', () => {
			$$voice.forEach(($voice) => {
				const $text = $voice.querySelector('.js-home-voice-text');
				const $arrow = $voice.querySelector('.js-home-voice-arrow');
				const $image = $voice.querySelector('.js-home-voice-image');

				const imageX = Number(getCssVar($image, '--_x'));
				const imageRotate = getCssVar($image, '--_rotate');

				gsap.set([$text, $arrow], {
					scale: 0,
					display: 'block',
				});

				gsap.set($image, {
					xPercent: imageX,
					opacity: 0,
				});

				const tl = gsap.timeline();

				const observer = new IntersectionObserver(
					(entries) => {
						entries.forEach((entry) => {
							if (entry.isIntersecting) {
								tl.to($image, {
									keyframes: {
										0: {
											xPercent: imageX,
											rotate: 0,
											opacity: 0,
										},
										20: {
											opacity: 1,
										},
										40: {
											xPercent: imageX * -0.89,
											rotate: imageRotate,
										},
										100: {
											xPercent: 0,
											rotate: 0,
										},
									},
									duration: 2,
									ease: Ease.SineMix,
								});

								tl.fromTo(
									$text,
									{
										scale: 0,
									},
									{
										scale: 1,
										duration: 0.4,
										ease: 'elastic.out(1, 0.4)',
									},
									'-=0.4'
								);

								tl.fromTo(
									$arrow,
									{
										scale: 0,
									},
									{
										scale: 1,
										duration: 0.4,
										ease: 'elastic.out(1, 0.4)',
									},
									'-=0.2'
								);

								// 初回のみ
								observer.disconnect();
							}
						});
					},
					{
						rootMargin: '-30% 0px -30% 0px',
					}
				);

				observer.observe($voice);
			});
		});
	}
});

window.addEventListener('load', () => {});
