// CSS変数取得
const getCssVar = (target, property) => {
	const value = getComputedStyle(target).getPropertyValue(property);

	if (typeof value === 'number') {
		return parseFloat(value);
	} else {
		return value;
	}
};

export { getCssVar };
